<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-data-table
        :headers="headers"
        :items="doctors"
        :items-per-page="10"
        :search="searchDoctor"
        :loading="loadingData"
        :expanded="expanded"
        single-expand
        show-expand
        item-key="id"
        no-data-text="No hay datos para mostrar..."
        loading-text="Cargando los datos, por favor espere!"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          disableItemsPerPage: false,
          itemsPerPageAllText: 'Todas',
          itemsPerPageText: 'Filas por página:',
        }"
      >
        <template v-slot:top>
          <v-toolbar flat class="align-center">
            <v-toolbar-title
              :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''"
            >
              Carrusel
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-responsive
              max-width="300"
              height="45"
              class="hidden-sm-and-down"
            >
              <!--<v-text-field
                id="findtext-doctor"
                label="Buscar.."
                name="finddoctor"
                outlined
                dense
                single-line
                clearable
                background-color="white"
                hide-details
                v-model="searchDoctor"
                class="white--text mt-0 pt-0 mr-2"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>-->
              <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <v-file-input
                  v-model="file"
                  :show-size="true"
                  :rules="fileRules"
                  accept="image/*"
                  label="Subir imagen"
                ></v-file-input>
                <v-btn @click="uploadFileX">Subir</v-btn>
              </form>
            </v-responsive>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h6 headline">
                  Estás seguro que desea eliminar este elemento?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" @click="deleteItemConfirm">
                    Proceder
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- //////////////////////////////////////////// -->
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <span class="text-h5">No hay registros en el listado!</span>
          <br />
          <v-btn color="primary" @click="getDoctorsa"> Recargar </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-row justify="start" class="ma-1">
                <h3>{{ item.name }}</h3>
              </v-row>
              <v-row class="ma-1">
                <v-col cols="12" md="10">
                  <v-row
                    :no-gutters="$vuetify.breakpoint.smAndDown"
                    justify="start"
                  >
                    <v-col cols="12" md="2"> Dirección: </v-col>
                    <v-col>
                      {{ item.address }}
                    </v-col>
                  </v-row>
                  <v-row
                    :no-gutters="$vuetify.breakpoint.smAndDown"
                    justify="start"
                  >
                    <v-col cols="12" md="2"> Email: </v-col>
                    <v-col>
                      {{ item.email }}
                    </v-col>
                  </v-row>
                  <v-row
                    :no-gutters="$vuetify.breakpoint.smAndDown"
                    justify="start"
                  >
                    <v-col cols="12" md="2"> Sitio Web: </v-col>
                    <v-col>
                      {{ item.web_page }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="2">
                  <v-row justify="center">
                    <v-img
                      :src="
                        item.pictures[0] != null
                          ? item.pictures[0].url
                          : require('@/assets/images/no-image.jpg')
                      "
                      :lazy-src="require('@/assets/images/no-image.jpg')"
                      max-height="150"
                      max-width="150"
                      aspect-ratio="1"
                      class="white--text align-center justify-center elevation-2"
                    >
                    </v-img>
                  </v-row>
                </v-col>
              </v-row>
              <v-row :dense="$vuetify.breakpoint.smAndDown" class="mx-1 my-5">
                <v-col cols="12" md="6">
                  Teléfono 1: {{ item.phone1 | formatPhone }}
                </v-col>
                <v-col cols="12" md="6">
                  Teléfono 2: {{ item.phone2 | formatPhone }}
                </v-col>
                <v-col cols="12" md="6">
                  Número de Licencia Profesional:
                  {{ item.profesional_license_number }}
                </v-col>
                <v-col cols="12" md="6">
                  Número de Licencia de la Especialidad:
                  {{ item.specialty_license_number }}
                </v-col>
                <v-col cols="12" md="6">
                  Número de Certificación Válida:
                  {{ item.certification_validity }}
                </v-col>
                <v-col cols="12" md="6">
                  Certificado Número: {{ item.certificate_number }}
                </v-col>
                <v-col cols="12">
                  Verificación de Licencia Profesional:
                  {{ item.professional_license_verification }}
                </v-col>
                <v-col cols="12" md="6">
                  Perfil en Facebook: {{ item.facebook_profile }}
                </v-col>
                <v-col cols="12" md="6">
                  Cuenta de Tweeter: {{ item.tweeterprofile }}
                </v-col>
                <v-col cols="12" md="6">
                  Perfil en Instagram: {{ item.instagram_profile }}
                </v-col>
                <v-col cols="12" md="6">
                  Canal de Youtube: {{ item.youtube_video }}
                </v-col>
                <v-col cols="12">
                  Horarios de Atención: {{ item.servicehours }}
                </v-col>
              </v-row>
              <v-row class="mx-1"> </v-row>
              <v-row class="mx-1"> </v-row>
              <v-row class="mx-1"> </v-row>
              <v-row class="mx-1"> </v-row>
              <v-row class="mx-1"> </v-row>
              <v-row class="mx-1"> </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackBar" :timeout="2000">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import doctorsService from "@/providers/DoctorsService";
import citiesService from "@/providers/CitiesService";
import certificationsService from "@/providers/CertificationsService";
import specialtiesService from "@/providers/SpecialtiesService";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "DoctorsModuleComponent",
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({
    // MapBox Location
    placeNameLimit: 60,
    selectedPlace: {
      place_name: "",
      center: [],
    },
    placesList: [],
    loadingPlaces: false,
    searchQuery: null,
    mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,

    file: null,
    fileRules: [
      //value => !!value || 'El archivo es requerido',
      value => !value || value.size < 1000000 || 'El tamaño del archivo no debe superar 1MB',
    ],

    // Default data
    focus: false,
    snackBar: false,
    snackText: "",
    valid: true,
    searchDoctor: "",
    loadingData: false,
    dialog: false,
    dialogDelete: false,
    dialogError: false,
    dialogDeletePicture: false,
    dialogGalleryPhotos: false,
    currentPicture: undefined,
    selectedFiles: [],
    imageFiles: [],
    messageError: "",
    associationsList: [],
    certificationsList: [],
    specialtiesList: [],
    doctors: [],
    cities: [],
    expanded: [],
    headers: [
      { text: "", value: "data-table-expand", width: 5 },
      { text: "Id", value: "id", align: " d-none" }, // para ocultar la columna Id.
      {
        text: "Nombre",
        align: "start",
        value: "name",
      },
      { text: "Dirección", value: "address" },
      { text: "E-mail", value: "email" },

      {
        text: "Activo",
        value: "active",
        width: 105,
      },

      { text: "Acciones", value: "actions", width: 105, sortable: false },
    ],
    textRules: [(v) => !!v || "Los datos de este campo son obligatorio!"],
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      register_number: "",
      description: "",
      description_english: "",
      address: "",
      email: "",
      phone1: "",
      phone2: "",
      map_lat: 0.0,
      map_lng: 0.0,
      city_id: 0,
      web_page: "",
      profesional_license_number: "",
      specialty_license_number: "",
      certification_validity: "",
      certificate_number: "",
      professional_license_verification: "",
      facebook_profile: "",
      twitter_profile: "",
      instagram_profile: "",
      youtube_video: "",
      membership: 0,
      servicehours: "",
      translation_id: 0,
      pictures: [],
      associations: [],
      certifications: [],
      specialties: [],
    },
    defaultItem: {
      id: 0,
      name: "",
      register_number: "",
      description: "",
      description_english: "",
      address: "",
      email: "",
      phone1: "",
      phone2: "",
      map_lat: 0.0,
      map_lng: 0.0,
      city_id: 0,
      web_page: "",
      profesional_license_number: "",
      specialty_license_number: "",
      certification_validity: "",
      certificate_number: "",
      professional_license_verification: "",
      facebook_profile: "",
      twitter_profile: "",
      instagram_profile: "",
      youtube_video: "",
      membership: 0,
      servicehours: "",
      translation_id: 0,
      pictures: [],
      associations: [],
      certifications: [],
      specialties: [],
    },

    dialogDeleteQr: false,
    dialogQrCode: false,
  }),
  filters: {
    formatPhone(value) {
      if (value === null || value == "undefined") {
        return "";
      }
      return (
        "(" +
        value.substring(0, 3) +
        ") " +
        value.substring(3, 6) +
        "-" +
        value.substring(6)
      );
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Médico" : "Editar Médico";
    },
    places() {
      let listPlaces = [];
      if (this.placesList) {
        listPlaces = this.placesList.map((entry) => {
          const PlaceName =
            entry.place_name.length > this.placeNameLimit
              ? entry.place_name.slice(0, this.placeNameLimit) + "..."
              : entry.place_name;
          console.log("PlaceName: ", PlaceName);
          return Object.assign({}, entry);
        });
      }
      return listPlaces;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDeletePicture(val) {
      val || this.closeDeletePicture();
    },
    searchQuery(val) {
      if (this.queryReady(val)) {
        this.searchPlaces(val);
      }
    },

    dialogDeleteQr(val) {
      val || this.closeDeleteQr();
    },
  },
  methods: {
    queryReady(val) {
      return val !== null && val !== undefined && val !== "";
    },
    onClear() {
      this.$emit("input", null);
      this.$emit("clear");
    },
    async searchPlaces(query) {
      // console.log('val:', val);
      if (query === null || query.length === 0) return;
      // Items have already been loaded
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
      this.loadingPlaces = true;
      // Lazily load input items
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          // console.log('res: ', res);
          const { query, features } = res;
          this.query = query;
          this.placesList = features;
          // console.log('places: ', this.places);
        })
        .catch((err) => {
          console.log("Error ejecutando API: ", err);
        })
        .finally(() => (this.loadingPlaces = false));
    },
    getSelectedItem(item) {
      // console.log('Item selected: ', item);
      this.selectedPlace = Object.assign({}, item);
    },

    isFechaVencida(fecha) {
      // Obtiene solo la parte de la fecha
      const fechaVencimiento = fecha.split("T")[0];
      // Formatea la fecha actual en el mismo formato
      const fechaActual = new Date().toISOString().split("T")[0];
      //console.log('Fecha Vencimiento:', fechaVencimiento);
      //console.log('Fecha Actual:', fechaActual);
      // Compara las fechas
      return fechaVencimiento > fechaActual;
    },
    isFechaCercanaTreintaDias(fecha) {
      // Convierte la fecha de vencimiento a objeto Date
      const fechaVencimiento = new Date(fecha);
      // Obtiene la fecha actual
      const fechaActual = new Date();
      // Calcula la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      // Convierte la diferencia a días
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      // Verifica si faltan 30 días
      return diferenciaEnDias == 30;
    },
    isFechaCercanaQuinceDias(fecha) {
      // Convierte la fecha de vencimiento a objeto Date
      const fechaVencimiento = new Date(fecha);
      // Obtiene la fecha actual
      const fechaActual = new Date();
      // Calcula la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaVencimiento - fechaActual;
      // Convierte la diferencia a días
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      // Verifica si faltan 15 días
      return diferenciaEnDias == 15;
    },
    enviarNotificacionCorreo(doctor_id, send_emails) {
      doctorsService.sendManyEmail(doctor_id, send_emails).then((data) => {
        if (data.success) {
          console.log("Correo enviado: ", data);
        } else {
          console.log("Error enviando correo: ", data);
        }
      });
    },
    getDoctors() {
      this.loadingData = true;
      // Nomenclador de Ciudades
      citiesService.getAllRecords().then((record) => {
        this.cities = record.value;
        // console.log("Ciudades: ", this.cities);
      });
      // Nomenclador de Certificaciones
      certificationsService.getViewList().then((record) => {
        this.certificationsList = record.value;
        // console.log("Certificaciones: ", this.certificationsList);
      });
      // Nomenclador de Especialidades
      specialtiesService.getViewList().then((record) => {
        this.specialtiesList = record.value;
        // console.log("Especialidades: ", this.specialtiesList);
      });
      // Catalogo de Medicos...
      doctorsService.getViewList().then((record) => {
        this.doctors = record.value;

        // Ordenar el array this.doctors, poniendo primero los objetos con coepris_qr
        this.doctors.sort((a, b) => {
          // Si a tiene coepris_qr y b no, a va primero
          if (a.coepris_qr && !b.coepris_qr) {
            return -1;
          }
          // Si b tiene coepris_qr y a no, b va primero
          else if (!a.coepris_qr && b.coepris_qr) {
            return 1;
          }
          // Si ambos tienen coepris_qr o ninguno tiene, no se cambia el orden
          else {
            return 0;
          }
        });

        // Enviar correo con 15 y 30 dias antes del vencimiento
        var valor = this.doctors;
        var send_emails = ["turismobc@gmail.com","assistence@baja.gob.mx","angelica.analistabi@gmail.com","lilianasolersilot89@gmail.com","potro23863@gmail.com"];
        for (var i = 0; i < valor.length; i++) {
          if (valor[i].coepris_qr && this.isFechaVencida(valor[i].vencimiento_fecha)){
            if (this.isFechaCercanaTreintaDias(valor[i].vencimiento_fecha)){
              this.enviarNotificacionCorreo(valor[i].id, send_emails);
            }
            if (this.isFechaCercanaQuinceDias(valor[i].vencimiento_fecha)){
              this.enviarNotificacionCorreo(valor[i].id, send_emails);
            }
          }
        }

        // console.log("Doctores: ", this.doctors);
        this.loadingData = false;
      });
    },

    uploadFileAdd() {
      /*
      // Aquí puedes enviar el archivo al servidor utilizando Axios o cualquier otra librería
      const urlUpdate = getUrlAPI + '/api/usersprofile/' + this.id_user + '/';
      // Crea una instancia de FormData y agrega el archivo seleccionado
      const formDataUpda = new FormData();
      formDataUpda.append('picture', this.file);
      formDataUpda.append('Pais', this.selectItemPais.id_pais);
      axios.patch(urlUpdate, formDataUpda)
        .then(response => {
            console.log("patch ya");
            console.log(response);
            this.initialize();
            this.file = null;
            //this.$router.push({ path: "/dashboards/home" });
            // Redirige a la página y recarga la aplicación completa
            //window.location.href = "";
        })
        .catch(error => {
            console.log(error);
        })
        */
    },

    uploadFile() {
      /*
      // Crea una instancia de FormData y agrega el archivo seleccionado
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('fileType', this.file.type);
      // Envía la solicitud POST utilizando Axios y agrega los encabezados necesarios para autenticar la solicitud con S3
      const uploadUrl = getUrlAPI + '/api/usersuploadfile/';
      axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          //headers: { 'Content-Type': this.file.type },
        })
        .then((response) => {
          console.log(response.data);
          console.log('Archivo cargado exitosamente en S3.');
          // Aquí puedes realizar las acciones adicionales que necesites después de cargar el archivo en S3
          this.uploadFileAdd();
        })
        .catch((error) => {
          console.log(error);
        });
      */
    },

    uploadFileX() {
      // Configura las credenciales de AWS
      /*
      AWS.config.update({
        accessKeyId: 'AKIAROQ7PYIKNYBJCN73',
        secretAccessKey: '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',
        region: 'us-east-1',
        //accessKeyId: 'TU_ACCESS_KEY_ID',
        //secretAccessKey: 'TU_SECRET_ACCESS_KEY',
        //region: 'TU_REGION',
      });
      */
      // Crea una instancia de S3
      //const s3 = new AWS.S3();
      // Crea una instancia de FormData y agrega el archivo seleccionado
      /*const formData = new FormData();
      formData.append('file', this.file);*/
      // Genera una firma de carga de S3 utilizando el SDK de AWS
      /*const params = {
        Bucket: 'ecowaste-store-prod',
        Key: 'images/' + this.file.name + '.jpg',
        //Bucket: 'NOMBRE_DE_TU_BUCKET',
        //Key: 'RUTA_DEL_ARCHIVO_EN_S3',
        ContentType: this.file.type,
      };*/
      /*const signedUrl = s3.getSignedUrl('putObject', params);
      const headerspicture = {
        headers: { 'Content-Type': this.file.type },
      };

      // Envía la solicitud POST utilizando Axios y agrega los encabezados necesarios para autenticar la solicitud con S3
      doctorsService.uploadFilePicture(signedUrl, formData, headerspicture).then((data) => {
        if (data.success) {
          console.log("Archivo cargado exitosamente en S3:", data.config.url);
          // Aquí puedes realizar las acciones adicionales que necesites después de cargar el archivo en S3
          this.uploadFileAdd();
        } else {
          console.log("Error S3: ", data);
        }
      });*/

    },

    newItem() {
      this.valid = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    editItem(item) {
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.vencimiento_fecha = this.formatDate(
        this.editedItem.vencimiento_fecha
      );
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.doctors.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.vencimiento_fecha = this.formatDate(
        this.editedItem.vencimiento_fecha
      );
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      doctorsService.deleteRecord(this.editedItem.id).then((response) => {
        if (response.success) {
          this.doctors.splice(this.editedIndex, 1);
          this.snackText = "Registro eliminado con éxito.";
          this.getDoctors();
        } else {
          this.snackText = "Un error impidió eliminar el registro!";
        }
      });
      this.closeDelete();
      this.snackBar = true;
    },
    close() {
      // this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        // console.log('selectedPlace: ', this.selectedPlace);
        if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
          this.editedItem.address = this.selectedPlace.place_name;
          this.editedItem.map_lng = this.selectedPlace.center[0];
          this.editedItem.map_lat = this.selectedPlace.center[1];
        }
        // console.log('editedItem: ', this.editedItem);
        if (this.editedIndex > -1) {
          // Actualizar el registro por el metodo PUT
          Object.assign(this.doctors[this.editedIndex], this.editedItem);
          doctorsService.updateRecord(this.editedItem).then((response) => {
            if (response.success) {
              this.getDoctors();
            }
          });
        } else {
          // Agrega el registro por el metodo POST
          this.doctors.push(this.editedItem);
          doctorsService.addRecord(this.editedItem).then((response) => {
            if (response.success) {
              this.getDoctors();
            }
          });
        }
        this.close();
        this.snackText = "Registro guardado con éxito.";
        this.snackBar = true;
      }
    },
    /* Para administrar las imagenes */
    loadGalleryImages(item) {
      this.selectedFiles = [];
      this.imageFiles = [];
      // Carga las imagenes...
      doctorsService.getRecord(item.id).then((fetch_data) => {
        this.editedItem = Object.assign({}, fetch_data.value);

        this.editedItem.vencimiento_fecha = this.formatDate(
          this.editedItem.vencimiento_fecha
        );
      });
      if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
    },

    // qr

    loadQrCode(item) {
      this.selectedFiles = [];
      this.imageFiles = [];
      // Carga las imagenes del Hospital seleccionado...
      doctorsService.getRecord(item.id).then((fetch_data) => {
        // console.log("record: ", fetch_data.value);
        this.editedItem = Object.assign({}, fetch_data.value);
        // console.log("editedItem", this.editedItem);

        this.editedItem.vencimiento_fecha = this.formatDate(
          this.editedItem.vencimiento_fecha
        );
      });
      if (!this.dialogQrCode) this.dialogQrCode = true;
    },

    deleteQr(item) {
      // Elimina la imagen seleccionada desde la bd y del servidor...
      this.currentPicture = item;
      // console.log(this.editedItem);
      this.dialogDeleteQr = true;
    },

    closeDeleteQr() {
      this.dialogDeleteQr = false;
    },

    deleteItemConfirmQr() {
      doctorsService
        .deleteQr(this.editedItem.id, this.currentPicture)
        .then((result) => {
          // console.log("Resultado de la eliminacion: ", result);
          this.closeDeleteQr();
          if (result.success) {
            this.snackText = "Qr eliminado con éxito!";
            this.loadQrCode(this.editedItem);
          } else {
            this.snackText = "Un error impidió eliminar el Qr!";
          }
          this.snackBar = true;
        });
    },

    uploadQr() {
      // files.length
      // console.log('Pictures a Subir: ', this.imageFiles);
      let errorCount = 0;
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
          // // console.log('Preview imagen: ',this.imagePreview, '\n');
        };
        reader.readAsDataURL(this.imageFiles[i]);
        doctorsService
          .uploadQr(this.editedItem.id, -1, this.imageFiles[i])
          .then((result) => {
            if (result.success) {
              this.loadQrCode(this.editedItem);
            } else {
              errorCount++;
            }
          });
      }
      this.snackText =
        errorCount > 0
          ? "Un error impidió subir el qr al servidor!"
          : "Qr subido al servidor!";
      this.snackBar = true;
      // // console.log('Pictures actualizado: ', this.editedItem.pictures);
      // this.loadGalleryImages(this.editedItem);
    },

    /////////////////////////////////////

    // vencimiento qr

    async updateVencimientoFecha(doctor) {
      try {
        const response = await doctorsService.updateRecord(doctor);

        if (response) {
          // Actualización exitosa, maneja cualquier acción necesaria
          this.getDoctors();
          this.dialogQrCode = false;

          this.snackText = "Fecha de vencimiento de coepris actualizado";

          this.snackBar = true;
          return response;
        } else {
          // Maneja el caso en el que la actualización no fue exitosa
          return null;
        }
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(itemDate) {
      // console.log(itemDate);
      if (itemDate) {
        const date = new Date(itemDate);
        // const year = date.getFullYear();
        // const month = String(date.getMonth() + 1).padStart(2, "0");
        // const day = String(date.getDate()).padStart(2, "0");

        const isoDate = date.toISOString().split("T")[0];

        // console.log(isoDate);

        return isoDate;
      }

      return null;
    },

    //////////////////////////////////////////////////

    removeChip(index) {
      this.imageFiles.splice(index, 1);
      this.selectedFiles = [...this.imageFiles];
    },
    inputChanged() {
      this.imageFiles = [...this.selectedFiles];
    },
    uploadPictures() {
      // console.log('id del record: ', this.editedItem.id);
      let errorCount = 0;
      for (let i = 0; i < this.imageFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFiles[i]);
        doctorsService
          .uploadImage(this.editedItem.id, -1, this.imageFiles[i])
          .then((result) => {
            if (result.success) {
              this.loadGalleryImages(this.editedItem);
            } else {
              errorCount++;
            }
          });
      }
      this.snackText =
        errorCount > 0
          ? "Un error impidió subir al menos una imágen al servidor!"
          : "Imágen subida al servidor. Galería actualizada!";
      this.snackBar = true;
    },
    deletePicture(item) {
      // Elimina la imagen seleccionada desde la bd y del servidor...
      this.currentPicture = Object.assign({}, item);
      this.dialogDeletePicture = true;
    },
    deleteItemConfirmPicture() {
      doctorsService
        .deleteImage(
          this.currentPicture.pivot.doctor_id,
          this.currentPicture.id
        )
        .then((result) => {
          this.closeDeletePicture();
          if (result.success) {
            this.snackText = "Imagen eliminada desde la galería!";
            this.loadGalleryImages(this.editedItem);
          } else {
            this.snackText =
              "Un error impidió eliminar la imágen desde la galería!";
          }
          this.snackBar = true;
        });
    },
    closeDeletePicture() {
      this.dialogDeletePicture = false;
    },
    changePreviewImage() {
      if (this.imageFile) {
        let reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(this.imageFile);
        if (this.editedItem.pictures.length === 0) {
          let imagePicture = {
            id: 2,
            module_id: 2,
            url: this.imageFile.name,
          };
          this.editedItem.pictures.push(imagePicture);
        }
      }
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
  mounted() {
    this.doctors = [];
    this.getDoctors();
    changeInformationSEO();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
