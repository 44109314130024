/***********************************************/
// Capa de Servicios para el modulo de Medicos.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/doctors");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/doctors");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/doctors");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewListByNumber(unique=false, venc=false, active=false) {
    try {
      let response = await axios.get(`${this.#baseUrl}/view-list-number/doctors?unique=${unique}&venc=${venc}&active=${active}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/doctors/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadDocument(doctorId, file, pdf, name){
    let form = new FormData();
    form.append("doctor", doctorId);
    form.append("file", pdf);
    form.append("name", name);
    form.append("miniature", file);
    return axios.post(`${this.#baseUrl}/upload-documtents/doctors`, form,
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                }
            )
  }

  async deleteDocument(id){
    return axios.post(`${this.#baseUrl}/delete-documtents/doctors`,
                {
                  id_document: id
                },
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                }
            )
  }

  async addRecord(formData) {
    try {
      await this.reload();
      let specialtiesList = formData.specialties;
      let response = await axios.post(this.#baseUrl + "/doctors", {
          name: formData.name,
          register_number: formData.register_number ? formData.register_number : "",
          description: formData.description,
          description_english: formData.description_english,
          address: formData.address,
          email: formData.email,
          phone1: formData.phone1,
          phone2: formData.phone2,
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          city_id: formData.city_id,
          web_page: formData.web_page,
          tiktok: formData.tiktok,
          excellence: formData.excellence,
          premium: formData.premium,
          repsabi: formData.repsabi,
          outstanding: formData.outstanding,
          profesional_license_number: formData.profesional_license_number ? formData.profesional_license_number : "",
          specialty_license_number: formData.specialty_license_number ? formData.specialty_license_number : "",
          certification_validity: formData.certification_validity ? formData.certification_validity : "",
          certificate_number: formData.certificate_number ? formData.certificate_number : "",
          professional_license_verification: formData.professional_license_verification ? formData.professional_license_verification : "",
          facebook_profile: formData.facebook_profile,
          twitter_profile: formData.twitter_profile,
          instagram_profile: formData.instagram_profile,
          membership: formData.membership ? formData.membership : "0",
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          coparmex: formData.coparmex,
          state: formData.state,
          whatsapp: formData.whatsapp,
          extra_services: formData.extra_services,
          speak_english: formData.speak_english,
          english: formData.english,
          professional_organism: formData.professional_organism,
          integral_services: formData.integral_services,
          extra_services_tourism: formData.extra_services_tourism,
          tourism_name: formData.tourism_name,
          repssabi_folio: formData.repssabi_folio,
          repssabi_expire_date: formData.repssabi_expire_date,
          conacem_date: formData.conacem_date,
          conacem_expire_date: formData.conacem_expire_date,
          accept_term: formData.accept_term,
          comment: formData.comment,
          register_repssabi: formData.register_repssabi,
          gender: formData.gender,
          rfc: formData.rfc,
          full_name: formData.full_name,
          ret: formData.ret,
          postal_code: formData.postal_code,
          colonia: formData.colonia,
          state_country: formData.state_country,
          city_country: formData.city_country,
          street: formData.street,
          num_ext: formData.num_ext,
          num_int: formData.num_int,
          municipality: formData.municipality,
          comment_ceetsb: formData.comment_ceetsb,
          comment_coparmex: formData.comment_coparmex,
          comment_coepris: formData.comment_coepris,
          state_ceetsb: formData.state_ceetsb,
          state_coparmex: formData.state_coparmex,
          state_coepris: formData.state_coepris,
          videos: formData.videos,
          member: formData.member,
          basic: formData.basic,
        }, {
          headers: this.header
        });
      if (response.data.success) {
        let doctor_id = response.data.id;
        let specialtiesIds = [];
        specialtiesList.forEach((item) => {
          specialtiesIds.push(item.id);
        });
        this.registerSpecialties(doctor_id, specialtiesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      let specialtiesList = formData.specialties;

      let response = await axios.put(
        `${this.#baseUrl}/doctors/${formData.id}`,
        {
          id: formData.id,
          name: formData.name,
          register_number: formData.register_number ? formData.register_number : "",
          description: formData.description,
          description_english: formData.description_english,
          address: formData.address,
          email: formData.email,
          phone1: formData.phone1,
          phone2: formData.phone2,
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          city_id: formData.city_id,
          web_page: formData.web_page,
          tiktok: formData.tiktok,
          excellence: formData.excellence,
          premium: formData.premium,
          repsabi: formData.repsabi,
          outstanding: formData.outstanding,
          profesional_license_number: formData.profesional_license_number ? formData.profesional_license_number : "",
          specialty_license_number: formData.specialty_license_number ? formData.specialty_license_number : "",
          certification_validity: formData.certification_validity ? formData.certification_validity : "",
          certificate_number: formData.certificate_number ? formData.certificate_number : "",
          professional_license_verification: formData.professional_license_verification ? formData.professional_license_verification : "",
          facebook_profile: formData.facebook_profile,
          twitter_profile: formData.twitter_profile,
          instagram_profile: formData.instagram_profile,
          membership: formData.membership ? formData.membership : "0",
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          coparmex: formData.coparmex,
          state: formData.state,
          whatsapp: formData.whatsapp,
          extra_services: formData.extra_services,
          speak_english: formData.speak_english,
          english: formData.english,
          professional_organism: formData.professional_organism,
          integral_services: formData.integral_services,
          extra_services_tourism: formData.extra_services_tourism,
          tourism_name: formData.tourism_name,
          repssabi_folio: formData.repssabi_folio,
          repssabi_expire_date: formData.repssabi_expire_date,
          conacem_date: formData.conacem_date,
          conacem_expire_date: formData.conacem_expire_date,
          accept_term: formData.accept_term,
          comment: formData.comment,
          register_repssabi: formData.register_repssabi,
          gender: formData.gender,
          rfc: formData.rfc,
          full_name: formData.full_name,
          ret: formData.ret,
          postal_code: formData.postal_code,
          colonia: formData.colonia,
          state_country: formData.state_country,
          city_country: formData.city_country,
          street: formData.street,
          num_ext: formData.num_ext,
          num_int: formData.num_int,
          municipality: formData.municipality,
          comment_ceetsb: formData.comment_ceetsb,
          comment_coparmex: formData.comment_coparmex,
          comment_coepris: formData.comment_coepris,
          state_ceetsb: formData.state_ceetsb,
          state_coparmex: formData.state_coparmex,
          state_coepris: formData.state_coepris,
          videos: formData.videos,
          member: formData.member,
          basic: formData.basic,
        },
        {
          headers: this.header
        }
      );

      if (response.data.success) {
        let doctor_id = response.data.id;
        let specialtiesIds = [];
        specialtiesList.forEach((item) => {
          specialtiesIds.push(item.id);
        });
        this.registerSpecialties(doctor_id, specialtiesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/doctors/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerSpecialties(doctor_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("doctor_id", doctor_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-specialties/doctors",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteSpecialties(doctor_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("doctor_id", doctor_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/delete-specialties/doctors",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(doctor_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(doctor_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  //  qr

  async deleteQr(doctor_id, coepris_qr) {
    if (coepris_qr) {
      try {
        await this.reload();
        let response = await axios.post(
          this.#baseUrl + "/delete-qr/doctors",
          {
            doctor_id: doctor_id,
            coepris_qr: coepris_qr
          },
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async uploadQr(doctor_id, qr_code_id, imageFile, isQr = true) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("qr_code_id", qr_code_id);
        formData.append("file", imageFile);
        formData.append("isQr", isQr);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/doctors",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  ////////////////////////////////////////////////

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/doctors?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  //////////////////////////////////////////////////////

  async uploadFilePicture(signedUrl, formData, headerspicture) {
    try {
      await this.reload();
      let response = await axios.put(signedUrl, formData, {...headerspicture, 
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }

  }


  ////////////////////////////////////////////////

  async sendManyEmail(profile_id, emails) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-manyemail/doctors?id=${profile_id}&emails=${emails}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  async uploadDocumentForm(id, identity, functionality_alert, cedula, specialty_cedula, specialty_conacem, photo, international_specialty, coepris_repssabi){
    let form = new FormData();
    form.append("id", id);
    form.append("functionality_alert", functionality_alert);
    form.append("cedula", cedula);
    form.append("specialty_cedula", specialty_cedula);
    form.append("specialty_conacem", specialty_conacem);
    form.append("photo", photo);
    form.append("international_specialty", international_specialty);
    form.append("coepris_repssabi", coepris_repssabi);
    form.append("identity", identity);
    return axios.post(`${this.#baseUrl}/add-documtents/doctors`, form,
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                }
            )
  }


  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
